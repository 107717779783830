.content-container {
  overflow-y: auto; /* Enable vertical scrolling for content that overflows */
  height: calc(
    100vh - 64px
  ); /* Adjust the height accordingly, considering the header's height (64px in this example) */
  position: relative;
}

.content-layout {
  z-index: 99;
}
